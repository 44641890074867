$content-width: 80%;


.ocean-hero-container {
  width: 100%;
  overflow: hidden;
}

.ocean-hero {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -100;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
    width: 80%;
    max-width: 800px;

    h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }
}

#diveMap {
  height: 70vh;
  width: 100%;
  max-height: 700px;
}

.dive-map-title {
  font-size: 80px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.dive-map-subtitle {
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.me-diving {
  margin-bottom: 20px;
  width: 200px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.leaflet-container {
  background-color: #aad3df !important;
}

.custom-div-icon .marker-pin {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #f36c25;
}

.throbbing {
  animation: throb 1.5s ease-in-out infinite;
}

@keyframes throb {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 768px) {
  #diveMap {
    height: 50vh;
  }
}

// Additional styles for Bootstrap modal customization if needed
.modal-dialog {
  width: 100%;
  max-width: 70%;
  margin: 1.75rem auto;
}

.modal-content {
  border-radius: 10px;
  height: auto;
  max-height: 80vh; // Limit the maximum height of the modal
}

.modal-body {
  padding: 20px;
  overflow-y: auto; // Allow scrolling if content exceeds max-height
}

.dive-info-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-start; // Align items to the top
}

.dive-image-container {
  flex: 0 0 50%;
  max-height: 60vh; // Limit the maximum height of the image container
  overflow: hidden; // Hide overflow if image is taller
}

.dive-text-container {
  flex: 1;
  overflow-y: auto; // Allow scrolling if text is too long
  max-height: 60vh; // Match the image container height
}

#diveImage {
  width: 100%;
  height: auto;
  object-fit: contain; // Ensure the entire image is visible
  max-height: 60vh; // Limit the maximum height of the image
}

#diveTitle {
  color: #005a8c;
  font-size: 32px; // Slightly reduced font size
  font-weight: bold;
  margin-bottom: 10px;
}

#diveDescription {
  line-height: 1.6;
  font-size: 16px; // Adjust font size as needed
}

@media (max-width: 767px) {
  .modal-dialog {
    max-width: 95%;
  }

  .dive-info-container {
    flex-direction: column;
  }
  
  .dive-image-container,
  .dive-text-container {
    max-height: none;
  }

  #diveImage {
    max-height: 40vh;
  }

  #diveTitle {
    font-size: 24px;
  }

  #diveDescription {
    font-size: 14px;
  }
}

// Updated styles for the close button
.modal-header {
  border-bottom: none;
  padding: 1rem 1rem 0.5rem;
}

.modal-header .close {
  padding: 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: none;
  opacity: .5;
  background: none;
  border: none;
  
  &:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
  }
}

.card {
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
}

// Add these styles at the end of the file

.seafood-consumption-section {
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 0px;
}

.seafood-consumption-section h2 {
  color: #005a8c;
  margin-bottom: 30px;
}

.seafood-consumption-section .lead {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.seafood-consumption-section h3 {
  color: #005a8c;
  margin-bottom: 20px;
}

.seafood-consumption-section ul {
  padding-left: 20px;
}

.seafood-consumption-section li {
  margin-bottom: 10px;
}

.cta-box {
  background-color: #e6f3ff;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}

.cta-box h3 {
  color: #005a8c;
}

.btn-primary {
  background-color: #005a8c;
  border-color: #005a8c;
  margin-top: 20px;
}

.btn-primary:hover {
  background-color: #004a73;
  border-color: #004a73;
}
